import { BookOutlined, FlagOutlined, FolderOpenOutlined, HomeOutlined, HourglassOutlined, LoginOutlined, LogoutOutlined, ProfileOutlined, UserOutlined } from '@ant-design/icons';
import React from 'react';
import { ROLES } from "./permissions";

export const routes = {
  home: {
    key: "home",
    slug: "home",
    to: "/home",
    title: "Dashboard",
    icon: <HomeOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
  },

  profile: {
    key: "profile",
    slug: "profile",
    to: "/profile",
    title: "Perfil",
    icon: <UserOutlined />,
    showInMenu: false,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
  },

  books: {
    key: "books",
    slug: "books",
    to: "/books",
    title: "Libros",
    icon: <BookOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },

  series: {
    key: "series",
    slug: "series",
    to: "/series",
    title: "Sagas",
    icon: <FolderOpenOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },

  goals: {
    key: "goals",
    slug: "goals",
    to: "/goals",
    title: "Metas",
    icon: <FlagOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },

  sagas: {
    key: "sagas",
    slug: "sagas",
    to: "/sagas",
    title: "Resumen sagas",
    icon: <ProfileOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },

  years: {
    key: "years",
    slug: "years",
    to: "/years",
    title: "Resumen libros",
    icon: <HourglassOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },

  users: {
    key: "users",
    slug: "users",
    to: "/users",
    title: "Usuarios",
    icon: <UserOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },

  login: {
    key: "login",
    slug: "login",
    to: "/login",
    title: "LOGIN",
    icon: <LoginOutlined />,
    showInMenu: false,
    showHeader: false,
    showSearch: false,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
  },

  logout: {
    key: "logout",
    slug: "logout",
    to: "/logout",
    title: "Cerrar Sesión",
    icon: <LogoutOutlined />,
    showInMenu: false,
    showHeader: false,
    showSearch: false,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
  },
};
