import { combineReducers } from "redux";
import {
  authorization,
  profile,
  currentRoute,
  notifications,
  app,
  usersForm,
  users,
  alerts,
  alertsForm,
  currentTheme,
  books,
  booksForm,
  series,
  seriesForm,
  goals,
  goalsForm,
} from "./Reducers";

export default combineReducers({
  app,
  authorization,
  currentRoute,
  profile,
  notifications,
  usersForm,
  users,
  alerts,
  alertsForm,
  currentTheme,
  books,
  booksForm,
  series,
  seriesForm,
  goals,
  goalsForm,
});
