import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_BOOK,
    DELETE_BOOK,
    BOOK_FORM_TOOGLE_LOADING,
    SET_BOOK_LIST,
    SHOW_NOTIFICATION,
    UPDATE_BOOK,
} from "../constants";
import { formatBooks } from "./settingsActionsUtils";

/* BOOK LIST */
export const fetchBooks = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.books.list)
            .then((response) => {
                const books = formatBooks(response.data);
                dispatch({
                    type: SET_BOOK_LIST,
                    payload: keyBy(books, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// BOOKS
export const createBook = (newBook) => {
    return async (dispatch) => {
        dispatch({ type: BOOK_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.books.create, newBook)
            .then((response) => {
                const book = formatBooks(response.data);
                dispatch({ type: ADD_NEW_BOOK, payload: book });
                dispatch({ type: BOOK_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Libro creado con éxito.",
                    },
                });
                return response?.status;
            })
            .catch((err) => {
                dispatch({ type: BOOK_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateBook = (updatedBook) => {
    return async (dispatch) => {
        dispatch({ type: BOOK_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.books.edit}${updatedBook?._id}`, updatedBook)
            .then((response) => {
                const book = formatBooks(response.data);
                dispatch({ type: UPDATE_BOOK, payload: book });
                dispatch({ type: BOOK_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Libro actualizado.",
                    },
                });
                return response?.status;
            })
            .catch((err) => {
                dispatch({ type: BOOK_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteBooks = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: BOOK_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.books.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_BOOK, payload: Ids });
                dispatch({ type: BOOK_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Libro eliminado.",
                    },
                });
                return response?.status;
            })
            .catch((err) => {
                dispatch({ type: BOOK_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
