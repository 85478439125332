// https://testing-core.deploy.kuarasoftware.es/

export const API = {
  auth: {
    login: `/user/login`,
    check2FA: `/user/check2FA`,
  },
  users: {
    profile: `/user/profile`,
    create: `/user/register/`,
    edit: `/user/`,
    delete: `/user/`,
    list: `/user`,
  },
  alerts: {
    create: `/alert`,
    edit: `/alert/`,
    delete: `/alert/`,
    list: `/alert`,
  },
  books: {
    create: `/book`,
    edit: `/book/`,
    delete: `/book/`,
    list: `/book`,
  },
  series: {
    create: `/serie`,
    edit: `/serie/`,
    delete: `/serie/`,
    list: `/serie`,
  },
  goals: {
    create: `/goal`,
    edit: `/goal/`,
    delete: `/goal/`,
    list: `/goal`,
  },
};
